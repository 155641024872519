<template ref="adminUser">
  <div>
    <header-admin-pages
      title="Изменения ОНК"
      :counter="$store.getters.getCommissionUpdateTypes.length"
      :button="{ title: 'Добавить статус законопроекта', link: '/admin/status_bill/create' }"
    />
    <main>
      <table>
        <tr>
          <td><p class="text-content">Заголовок</p></td>
          <td></td>
        </tr>
        <tr v-for="(item, i) in $store.getters.getCommissionUpdateTypes" :key="`members${i}`">
          <td><p class="text-content">{{ item.title }}</p></td>
          <td>
            <drop-list @clickItem="clickItem" :id-item="item.id"
                :edit-link="`/admin/status_bill/${item.id}/edit`"/>
          </td>
        </tr>
      </table>
    </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
import DropList from '@/components/Admin/DropList'
export default {
  name: 'StatusBill',
  methods: {
    clickItem (value) {
      if (value.value === 'del') {
        const consent = confirm('Вы уверены, чтобы хотите удалить законопроект')
        if (consent) {
          this.$store.dispatch('deleteCommissionUpdateTypes', value.id)
        } else {
          this.$noty.warning('Удаление отменено')
        }
      } else {
      }
    }
  },
  mounted () {
    this.$store.dispatch('setCommissionUpdateTypes')
  },
  components: {
    HeaderAdminPages,
    DropList
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  display: flex;
  justify-content: space-between;
  justify-items: baseline;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.wrapper-btn-filters {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}

.text-content{
  width: 15rem;
}
</style>
